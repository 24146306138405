<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/dashboard">Phone Auth</a>
    <ul class="navbar-nav ms-auto flex-nowrap">
      <li class="nav-item">
        <div style="cursor: pointer;" (click)="logout()" class="nav-link m-2 menu-item nav-active">Logout</div>
      </li>
    </ul>
  </div>
</nav>

<h2 class="mt-5">Welcome to the Dashboard user {{userData}} </h2>
