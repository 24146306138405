import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';



Sentry.init({
  dsn: "https://fd718648890fb8e2452cffe89e63c6fc@o4506097209311232.ingest.sentry.io/4506097211736064",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", 'https://smart-dca-section.d2lzxxaltd5jgo.amplifyapp.com', 'https://youngplatform.com', 'https://smart-dca.web.app', 'https://smartdca-prod.web.app'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
