<div class="registration-form">
  <div class="flex-box">
    <div class="upper">
      <h1 class="title">{{"step4.tnc" | translate}}</h1>
      <div class="tnc">
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.25in;
            margin-bottom: 0.25in;
            text-align: center;
          "
        >
          <span size="2" style="font-size: 13px"
            ><strong>SOFTWARE LICENCE AGREEMENT</strong></span
          >
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.25in;
            background: transparent;
            margin-top: 0.25in;
          "
        >
          <span size="2" style="font-size: 13px"
            ><strong>INTRODUCTION</strong></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            >These terms <span lang="en-US">and conditions (the &ldquo;</span
            ><span lang="en-US"><strong>Agreement</strong></span
            ><span lang="en-US"
              >&rdquo;) cover the provision of the Software (as defined below)
              and&nbsp;</span
            >set forth a legally binding contract between you (the &ldquo;<span
              lang="en-US"
              ><strong>Licensee</strong></span
            >&rdquo;) and DIGITAL WEALTH LIMITED, a private limited liability
            company registered under the laws of Malta, with company
            registration number C 102193 and its registered office at 4<sup
              >th</sup
            >
            Floor, Kingsway Palace, Republic Street Valletta VLT 1115, Malta
            <span lang="en-US">(the &ldquo;</span
            ><span lang="en-US"><strong>Licensor</strong></span
            ><span lang="en-US"
              >&rdquo;) in respect of its licence grant for the Software, as
              further described below at the recitals.</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span lang="en-US">The Licensor</span> has the right to change
            the<span lang="en-US">se terms and conditions&nbsp;</span
            >unilaterally in accordance with its Clause 1<span lang="en-US"
              >5.4</span
            >, including for reasons where such changes are caused by amendments
            to the law or developments relating to its business.
            <span lang="en-US">The Licensor&nbsp;</span>shall notify the
            <span lang="en-US">Licensee</span> of
            <span lang="en-US">such changes at&nbsp;</span>least two (2) weeks
            in advance before the amended
            <span lang="en-US">terms and/or conditions</span> enter into force.
            If the <span lang="en-US">Licensee</span> does not accept the
            changes, it shall have a right to terminate the Agreement effective
            immediately by informing
            <span lang="en-US">the Licensor&nbsp;</span>thereof in a writing
            within two (2) weeks of receiving the notification of amendment. If
            the <span lang="en-US">Licensee</span> does not notify<span
              lang="en-US"
              >&nbsp;the Licensor</span
            >, it <span lang="en-US">shall be&nbsp;</span>deemed to have fully
            and unconditionally accepted
            <span lang="en-US">such changes.</span></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            >This Agreement enters into force
            <span lang="en-US"
              >immediately upon the Licensee&rsquo;s acceptance&nbsp;</span
            >
            (by clicking &ldquo;I accept&rdquo;<span lang="en-US"
              >).</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span lang="en-GB">Each individually referred to as a &ldquo;</span
            ><span lang="en-GB"><strong>Party</strong></span
            ><span lang="en-GB">&rdquo; and jointly as the &ldquo;</span
            ><span lang="en-GB"><strong>Parties</strong></span
            ><span lang="en-GB">&rdquo;.</span></span
          >
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.25in;
            background: transparent;
            margin-top: 0.25in;
          "
        >
          <span size="2" style="font-size: 13px"
            ><strong>RECITALS</strong></span
          >
        </p>
        <ol type="A">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: left;
              "
            >
              <span size="2" style="font-size: 13px"
                >WHEREAS, the Licensor intends to grant the Licensee a
                non-exclusive licence to use the Software for the Licensed
                Purpose (both terms as defined below);</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.69in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol type="A" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: left;
              "
            >
              <span size="2" style="font-size: 13px"
                >WHEREAS, the Licensee, having received sufficient information
                from the Licensor on the Software (including its technical
                information and its risks of use), desires to obtain this
                non-exclusive licence in order to be able to conduct the
                Licensed Purpose;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.69in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol type="A" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: left;
              "
            >
              <span size="2" style="font-size: 13px"
                >WHEREAS the Parties thus wish to enter into this Agreement to
                set forth the terms that will regulate their respective rights
                and obligations in connection with this licence
                grant.&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            margin-bottom: 0.08in;
            background: transparent;
            line-height: 115%;
            text-align: left;
          "
        >
          <span size="2" style="font-size: 13px"
            >NOW, THEREFORE, in consideration of the foregoing recitals and the
            mutual covenants and agreements contained herein and for other good
            and valuable consideration, the sufficiency of which is hereby duly
            acknowledged, the Parties agree as follows:</span
          >
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.25in;
            background: transparent;
            margin-top: 0.25in;
          "
        >
          <span size="2" style="font-size: 13px"
            ><strong>AGREED TERMS</strong></span
          >
        </p>
        <ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Definitions and&nbsp;</span
                ><span size="2" style="font-size: 13px"
                  >Interpretation</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    ><strong>Definitions.</strong></span
                  ><span size="2" style="font-size: 13px"
                    >&nbsp;In this Agreement and its Schedules, the following
                    expressions have the meanings stated, unless the context
                    otherwise requires:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Business Day</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means a day on which banks in Malta are ordinarily open
                for business, other than a Saturday, Sunday or public holiday in
                Malta;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Commencement Date</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the date of this Agreement;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Control</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the possession, directly or indirectly, of the
                power to manage the assets, and/or otherwise determine or cause
                the determination of the conduct of the affairs of an entity, be
                it through the ownership of voting securities, by contract,
                agency or otherwise, which power shall in any case be deemed to
                exist by the possession, directly or indirectly, of either (i)
                the right to exercise (or the factual exercise of) more than 50%
                (fifty percent) of all the votes exercisable at a general
                meeting of shareholders; or (ii) the right to appoint or cause
                the appointment of a majority of the members of the board of
                directors; or (iii) the right or ability to manage all (or
                substantially all) of the funds or assets of such entity (and,
                in that regard, the terms &ldquo;</span
              ><span size="2" style="font-size: 13px"><em>Controlling</em></span
              ><span size="2" style="font-size: 13px">&rdquo;, &ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><em>Controlled by</em></span
              ><span size="2" style="font-size: 13px">&rdquo;, &ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><em>under Common Control with</em></span
              ><span size="2" style="font-size: 13px">&rdquo; and &ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><em>Change of Control</em></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; shall be construed accordingly);</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Deliverables</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means any Confidential Information, Intellectual
                Property Rights, software, data, or any other information or
                documentation provided by the Licensor as part of the Software
                or otherwise for the conduct of the Licensed Purpose;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Euros</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; and/or &ldquo;</span
              ><span size="2" style="font-size: 13px"><strong>EUR</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the lawful currency of the European Union;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Improvement</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means (i) any change, customisation, modification,
                addition, enhancement, revision, adaptation, abridgement or
                expansion to the Software; (ii) any other form of derivative
                work, as understood by applicable copyright or other
                intellectual property legislation, which is created, developed
                or derived from the Software; (iii) any discovery or invention
                which is based on or otherwise derived from the Software; or
                (iv) any independent software, or other computer program, which
                is developed or derived from the Software; in each case whether
                in whole or in part and/or in respect of any of its
                components;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Intellectual Property Rights</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means patents, utility models, rights to inventions,
                copyright and related rights, trade marks and service marks,
                business names and domain names, rights in get-up and trade
                dress, goodwill and the right to sue for passing off or unfair
                competition, rights in designs, database rights, rights to use,
                and protect the confidentiality of, confidential information
                (including know-how and trade secrets), and all other
                intellectual property rights, in each case whether registered or
                unregistered and including all applications and rights to apply
                for and be granted, renewals or extensions of, and rights to
                claim priority from, such rights and all similar or equivalent
                rights or forms of protection which subsist or will subsist now
                or in the future in any part of the world;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Licence</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the licence granted to the Licensee under Clause
                ;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Licence Fee</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the fee payable by the Licensee for the Licence
                under Clause ;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Licensee&rsquo;s Parameters</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the Licensee&rsquo;s chosen configuration and
                algorithms to define its trading, risk and capital management
                strategies, which shall be determined by the Licensee at its
                absolute discretion;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Licensed Purpose</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means the scope of the Licence, as per Clause ;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Regulatory Authority</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means: (a) any governmental authority and any
                department, ministry, or agency of any government; and (b) any
                other authority, agency, commission or similar entity having
                powers or jurisdiction under any law or regulation;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                margin-bottom: 0.08in;
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>Software</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means Licensor&rsquo;s software program known by the
                name of Trend Follower automatic trading BOT, as further
                described at Schedule 1;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.19in;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              &ldquo;<span size="2" style="font-size: 13px"
                ><strong>VAT</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo; means value added tax chargeable in Malta.</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol>
          <ol start="2">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  ><strong>Interpretation</strong></span
                ><span size="2" style="font-size: 13px"
                  >. In this Agreement:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >clause, schedule and paragraph headings are inserted for sake
                of convenience;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >a person includes a natural person, corporate or unincorporated
                body (whether or not having separate legal
                personality);&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Schedules form part of this&nbsp;Agreement&nbsp;and shall
                have effect as if set out in full in the body of
                this&nbsp;Agreement. Any reference to
                this&nbsp;Agreement&nbsp;includes the Schedules;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >words in the singular shall include the plural and&nbsp;</span
              ><span size="2" style="font-size: 13px"><em>vice-versa</em></span
              ><span size="2" style="font-size: 13px">;</span>
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >a reference to a statute or statutory provision is a reference
                to it as in force as at the Commencement Date;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="6">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >a reference to one gender shall include a reference to the
                other genders;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="7">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any words following the terms&nbsp;</span
              ><span size="2" style="font-size: 13px"
                ><strong>including</strong></span
              ><span size="2" style="font-size: 13px">,&nbsp;</span
              ><span size="2" style="font-size: 13px"
                ><strong>include</strong></span
              ><span size="2" style="font-size: 13px">,&nbsp;</span
              ><span size="2" style="font-size: 13px"
                ><strong>in particular</strong></span
              ><span size="2" style="font-size: 13px">,&nbsp;</span
              ><span size="2" style="font-size: 13px"
                ><strong>for example&nbsp;</strong></span
              ><span size="2" style="font-size: 13px"
                >or any similar expression shall be construed as illustrative
                and shall not limit the sense of the words, description,
                definition, phrase or term preceding those terms;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="8">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >a reference
                to&nbsp;&ldquo;writing&rdquo;&nbsp;or&nbsp;&ldquo;written&rdquo;&nbsp;includes
                fax and email; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="9">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any obligation on a Party not to do something includes an
                obligation not to allow that thing to be done.</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol>
          <ol start="3">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >In the case of conflict or ambiguity between any provision in
                  the body of this Agreement and any provision contained in the
                  Schedules, the provision in the body of this Agreement shall
                  take precedence and prevail.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >This Agreement shall be binding on, and enure to the benefit
                  of, the Parties and their successors and permitted assigns,
                  and references to any Party shall include that Party&apos;s
                  successors and permitted assigns.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                ><strong>Licence Grant</strong></span
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: left;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >In consideration of the Licence Fee (as and when it falls
                    due) and subject to the Licensee&rsquo;s compliance with all
                    the other terms in this Agreement, the Licensor hereby
                    grants to the Licensee, commencing from the Commencement
                    Date and effective for the term of this Agreement, a
                    non-exclusive, non-transferrable and non-sublicensable right
                    and licence to:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.63in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >deploy the Software on the Licensee&rsquo;s systems; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >use the Software for the Licensed Purpose.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="2">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >If the Licensee requires or obtains any additional software
                  components from third parties, in relation to the Licensed
                  Purpose or otherwise, which are not part of the Software
                  (e.g., bridge software for interconnectivities etc,), all
                  related costs shall be borne by the Licensee.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee may only use the Software for the purpose of
                  facilitating and carrying out trading decisions in the
                  Licensee&rsquo;s own name and for its own account (the
                  &ldquo;</span
                ><span size="2" style="font-size: 13px"
                  ><strong>Licensed Purpose</strong></span
                ><span size="2" style="font-size: 13px"
                  >&rdquo;). The rights granted to the Licensee under the
                  Licence shall at all times be construed and limited
                  accordingly. For the avoidance of doubt, the Licensor and
                  Licensee acknowledge that neither the Licensed Purpose nor the
                  licence grant fall within the parameters of an
                  &ldquo;investment service&rdquo; as defined under the
                  Investment Services Act (Chapter 370 of the laws of Malta) and
                  that consequently regulatory approval, by the Malta Financial
                  Services Authority (&ldquo;</span
                ><span size="2" style="font-size: 13px"
                  ><strong>MFSA</strong></span
                ><span size="2" style="font-size: 13px"
                  >&rdquo;) or other similar public authority, is not required.
                  Each Party reserves the right to review and withdraw its
                  participation in this Agreement should there be any changes to
                  the above situation.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >All current and future rights of use, exploitation and
                  licensing of the Software, including any of its Components or
                  the Algorithm, to or for the benefit of any other person or in
                  relation to any other collective investment scheme are hereby
                  reserved in full by the Licensor, and the Licensee hereby
                  accepts and acknowledges that it has no rights and shall have
                  no rights thereto, save for the limited right to exercise the
                  Licensed Purpose as detailed in Clause 2.2 above.</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol>
          <ol start="5">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >In relation to scope of &ldquo;</span
                ><span size="2" style="font-size: 13px"
                  ><strong>use</strong></span
                ><span size="2" style="font-size: 13px"
                  >&rdquo; under clause 2.1:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >use of the Software shall be restricted to use of its software
                in object code form only and within the scope of the Licensed
                Purpose;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee has no right, and shall not permit any third
                party, to</span
              ><span size="2" style="font-size: 13px"
                >&nbsp;make copies of,&nbsp;</span
              ><span size="2" style="font-size: 13px"
                >adapt, reverse engineer, decompile, disassemble, or make error
                corrections to, or&nbsp;</span
              ><span size="2" style="font-size: 13px"
                >derive or discover the source code of,</span
              ><span size="2" style="font-size: 13px"
                >&nbsp;the Software, whether in whole or in part, and shall not
                make any attempt whatsoever to carry out any of the above,
                except solely to the extent that (by virtue of article 9(2) of
                the Copyright Act, Chapter 415 of the laws of Malta) such
                actions cannot be prohibited as they are essential for the
                purpose of achieving inter-operability of the Software with the
                operation of other software or systems used by the Licensee
                (&ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><strong>Permitted Objective</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo;), and provided that the information obtained by the
                Licensee during the course of such activities:</span
              >
            </p>
          </li>
        </ol>
        <ol type="a">
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span style="font-style: normal"
                >is not disclosed or communicated without the Licensor&rsquo;s
                prior written consent to any third party to whom it is not
                necessary to disclose or communicate it in order to achieve the
                Permitted Objective;</span
              >
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  >is not used to develop, create or market any software or
                  other product which is substantially similar to&nbsp;</span
                ></span
              ><span size="2" style="font-size: 13px"
                ><span lang="en-US"
                  ><span style="font-style: normal"
                    >any of the Licensed Technology</span
                  ></span
                ></span
              ><span style="font-style: normal">;</span>
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span style="font-style: normal">is kept secure; and</span>
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: normal;
              "
            >
              is used strictly for the Permitted Objective.
            </h6>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol>
          <ol start="6">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee has no right to:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: left;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >distribute, re-distribute, sell, lease, assign, convey,
                transfer, trade, rent or publish the Software, whether in whole
                or in part; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >authorise or allow any third parties to access or use the
                Software in any manner whatsoever, except for those third
                parties who have been specifically authorised in advance and in
                writing by the Licensor.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee shall permit the Licensor to inspect and have
                  access to its systems and to have access to any records kept
                  by, or on behalf of, the Licensee in connection with
                  this&nbsp;licence grant, for the purposes of ensuring that the
                  Licensee is complying with the terms of this Agreement,
                  provided that the Licensor gives reasonable advance notice in
                  writing to the Licensee of the planned inspection, which shall
                  take place during normal business hours.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licence does not include Improvements made by, or on
                  behalf of, the Licensor after the Commencement Date and no
                  rights are being granted under this Agreement to the Licensee
                  in respect of any such Improvements. Nothing contained in this
                  Agreement shall be deemed to impose any obligation on the
                  Licensor, whether by implication or otherwise, to create,
                  disclose, licence or grant any rights to, or allow the use of,
                  any Improvements to or by the Licensee and, for any and all
                  such cases, the Licensor reserves the right to do so only
                  subject to the payment of additional licence fees. To the
                  extent that any Improvements are provided by the Licensor to
                  the Licensee, they shall be deemed to be incorporated within
                  the definition of the Software and shall be subject to the
                  terms and conditions of this Agreement.&nbsp;</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: left;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licence shall come into effect, to the benefit of the
                  Licensee, as from the Commencement Date and shall continue and
                  remain in full force and effect for the term of this
                  Agreement, unless terminated by either Party in accordance
                  with the provisions of Clause .</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Assignment and sub-licensing</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >This licence does not include the right to sublicence, and
                    the Licensee may not sublicense all or any of its rights
                    under the licence without the prior written consent of the
                    Licensor, which may be freely withheld by the Licensor
                    without any liability or otherwise made subject to whatever
                    terms or conditions that the Licensor may deem fit to
                    impose.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall not (without the prior consent of the
                    Licensor):</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >assign, transfer or novate the benefit or burden of this
                licence in whole or in part;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >allow the Software to become the subject of any charge,
                mortgage, pledge, hypothec or other encumbrance or
                burthen;</span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >declare a trust over, or deal in any other manner with, any or
                all of its rights and obligations under this Agreement.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="3">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >A Change of Control of the Licensee, whether by purchase,
                  merger, subscription, allotment, stock transfer or otherwise,
                  will be deemed to amount to an assignment, transfer, or
                  novation of the licence in terms of clause 3.2. The Parties
                  agree and covenant as follows:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee shall give the Licensor seven (7) Business
                Days&rsquo; prior written notice before consummating or
                undergoing any Change of Control; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >in any and all such cases, the Licensor shall have, and hereby
                reserves in full, the right to terminate this Agreement, without
                fault or liability for the Licensor, by giving three (3)
                months&rsquo; written notice to the Licensee in the event that
                the Licensee decides to proceed with the Change of Control
                despite the Licensor&rsquo;s objection.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensor may, at any time, freely assign, mortgage,
                  charge, subcontract, declare a trust over or deal in any other
                  manner with any or all of its rights under this Agreement,
                  provided that it gives prior written notice of any such
                  dealing to the Licensee.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 0.21in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong>Deployment&nbsp;</strong>
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >With regards to the Software:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensor will deploy the Software on the Licensee&rsquo;s
                systems to the extent required, according to the reasonable
                written instructions received by the Licensee. Once deployed, it
                will allow the Licensee to conduct the Licensed Purpose;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >after being deployed, the Software shall be used by the
                Licensee in its own name and for its own account. The Licensor
                shall not be obliged or entitled to make any alteration to the
                Licensee&rsquo;s Parameters; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it is intended that the Licensee shall operate those parts of
                the Software, as deployed on the Licensee&rsquo;s systems, on
                its own. Nevertheless, the Licensee may instruct the Licensor to
                modify the Licensee&rsquo;s Parameters on its behalf. Such
                instruction must be clear, in writing and shall not leave any
                discretion to the Licensor. The timing for the execution of
                these instructions lies at the sole discretion of the Licensor,
                and in particular Licensor shall not be required to perform them
                outside of business hours or on any days which are not a
                Business Day.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  ><strong>Training and Information.&nbsp;</strong></span
                ><span size="2" style="font-size: 13px"
                  >The Licensor shall (before the completion of the deployment
                  of the Software on the Licensee&rsquo;s systems) provide the
                  Licensee with the training, technical information, simulation
                  results, comments and other relevant information reasonably
                  needed to assist the Licensee to (i) use the Software on its
                  own, (ii) conduct the Licensed Purpose, and (iii) be able to
                  technically adapt the Licensee&rsquo;s Parameters if
                  preferred. Such training and information shall however be
                  provided in a general form, and not with regard to or in the
                  context of specific trades. It will not constitute any form of
                  trading or investment advice.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee shall be solely responsible for operating and
                  monitoring those components of the Software that are installed
                  or deployed on the Licensee&rsquo;s systems.&nbsp;</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licence is strictly personal to the Licensee, and the
                  Licensee may only use the Software on its internal systems.
                  The Licensee shall be in material breach of this Agreement if
                  it transfers, or attempts to transfer, the Software (or any
                  copies thereof) to any third-party systems, including any
                  which may belong to any affiliates or group company of the
                  Licensee.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 0.21in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                ><strong>Licence Fee</strong></span
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall pay to the Licensor the&nbsp;</span
                  ><span size="2" style="font-size: 13px"
                    ><u>Licence Fee</u></span
                  ><span size="2" style="font-size: 13px"
                    >, in accordance with the provisions of Schedule 2, for its
                    receipt and use of the Software and all other benefits and
                    rights granted to the Licensee under this Agreement.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >All sums payable herein are exclusive of VAT and any other
                    relevant local sales tax, for which the Licensee alone shall
                    be responsible; and shall be paid by the Licensee in full
                    without set-off or counterclaim and free and clear of and
                    without any deduction or withholding or payment for or on
                    account of any present or future tax, levy, duty, impost or
                    other charge or withholding of a similar nature. If the
                    Licensee is required by law to effect any such deduction or
                    withholding or payment, the Licensee shall immediately pay
                    to the Licensor such additional amount as will result in the
                    receipt by the Licensor of the full amount that would
                    otherwise have been received had no such deduction or
                    withholding or payment been made.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >If the Licensee fails to make any payment due to the
                    Licensor under this Agreement by the due date for payment,
                    then, without limiting any of the Licensor&rsquo;s other
                    rights and remedies under this Agreement, the Licensee shall
                    pay interest on the overdue amount at the rate of eight per
                    cent (8%) per annum. Such interest shall accrue on a daily
                    basis against the Licensee from the due date until the date
                    of actual payment of the overdue amount (whether before or
                    after a judgement or decision conferring an executive title
                    on the Licensor). The Licensee shall be bound to pay the
                    accrued interest, together with the overdue amount, to the
                    Licensor.</span
                  >
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 0.21in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Confidentiality&nbsp;</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Each Party (the &ldquo;</span
                  ><span size="2" style="font-size: 13px"
                    ><strong>Receiving Party</strong></span
                  ><span size="2" style="font-size: 13px"
                    >&rdquo;) shall keep confidential all, and shall not use for
                    its own purposes (other than the implementation of this
                    Agreement), nor without the prior written consent of the
                    other Party disclose to any third party (except its
                    professional advisors or as may be required by any law or
                    any legal or regulatory authority), any Confidential
                    Information relating to the other Party or any of its group
                    companies which may become known to that Receiving Party as
                    a result of or in connection with this Agreement. Each Party
                    shall use its reasonable endeavours to prevent and protect
                    against the unauthorised disclosure or the unauthorised
                    acquisition of any such Confidential Information.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Parties obligations with respect to Confidential
                    Information under this Clause 6 shall remain in force for
                    the term of the Agreement and, thereafter, for a period of
                    five (5) years following its termination, unless however a
                    longer period of protection applies under</span
                  ><span size="2" style="font-size: 13px"
                    >applicable law, whether as a trade secret or due to
                    fiduciary obligations or otherwise.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Nevertheless, a Party assigning any of its rights under
                    this Agreement (in accordance with the terms herein) may
                    disclose to a proposed assignee any information in its
                    possession that relates to this Agreement, its
                    subject-matter, the negotiations relating to it or the other
                    Party, where such disclosure is necessary for the purposes
                    of the proposed assignment.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >A Party shall not be in breach of its obligations hereto if
                    it is required to disclose the relevant Confidential
                    Information by virtue of any applicable law, statute or
                    other regulation, or any court order or where ordered or
                    required to do so by any Regulatory Authority or
                    self-regulatory organisation (whether of a governmental
                    nature or otherwise) with competence over it or similar
                    process enforceable in any relevant jurisdiction. However,
                    should Confidential Information be required to be disclosed
                    to any court, tribunal or Regulatory Authority under this
                    clause, the Party shall (unless prohibited from doing so)
                    advise the other Party of the nature and content of its
                    required disclosure so as to afford that other Party the
                    opportunity, to the extent possible, to apply for a
                    protective order against such disclosure.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall, at all times, safeguard the
                    confidential and proprietary nature of the Software, and
                    shall not directly or indirectly disclose, or provide access
                    to, any component or aspect of the Software (including any
                    Improvements) to any third party, except and unless the
                    prior consent of the Licensor is obtained in writing. The
                    Licensee hereby warrants and undertakes to the Licensor that
                    it shall establish, maintain and enforce policies and
                    procedures in respect of its obligations hereto which are no
                    less rigorous than the standards used to protect its own
                    confidential and proprietary information.&nbsp;</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall be responsible to the Licensor for all
                    acts and omissions of its employees, officers,
                    representatives, group companies, sub-licensees and other
                    third parties (including their respective employees,
                    officers and representatives) who obtain access to the
                    Licensed Technology, as if such acts or omissions were the
                    Licensee&rsquo;s own acts and/or omissions.&nbsp;</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >For the purposes of this Agreement, Confidential
                    Information means all information that is by its nature
                    confidential, or that is designated as confidential (whether
                    in writing, verbally or by any other means and whether
                    directly or indirectly), or either Party knows or ought to
                    know is confidential, whether before, on or after the date
                    of this Agreement and includes:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the terms of this Agreement;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px">the Software;</span>
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >all information, whether of a commercial or technical nature or
                otherwise, relating in any manner to the internal,
                administrative, business or financial affairs, operations or
                policies, practices, products or planned products, licensees,
                suppliers, customers, markets and other spheres of operation,
                research, inventions, know-how, designs and source codes and
                trade secrets of the Licensor;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >all know-how, techniques, ideas, principles and concepts which
                underlie any element of the Software; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >all other information treated by either Party as confidential,
                or capable of being protected at law or equity as confidential
                information, or the disclosure of which might cause loss or
                damage or otherwise adversely affect either Party,</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            margin-bottom: 0.08in;
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-top: 0.19in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><strong>but does not include</strong></span
          ><span size="2" style="font-size: 13px"
            >&nbsp;any information that (i) was already public knowledge prior
            to the Commencement Date; or (ii) was already lawfully known to the
            Receiving Party at the time of disclosure or receipt thereof; or
            (iii) subsequently becomes public knowledge other than by a breach
            of this Agreement.</span
          >
        </p>
        <ol start="7">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                ><strong>Ownership and Improvements</strong></span
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee accepts and acknowledges that:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Software (including all Improvements), all Deliverables and
                all their Intellectual Property Rights (whether existing now or
                arising any time hereafter) are proprietary to the Licensor, and
                all rights, title and interest thereto vest in, and are and
                shall at all times remain the sole and exclusive property of,
                the Licensor;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee has only obtained a limited right under this
                Agreement to use the Software for the Licensed Purpose and only
                for the term of this Agreement and subject to the
                Licensee&rsquo;s compliance with all the other terms and
                conditions contained herein. All rights to the Software not
                expressly granted to the Licensee under this Agreement are
                reserved in full by the Licensor; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >nothing in this Agreement shall be deemed to be, or construed
                as, any abandonment, revocation, waiver, assignment or denial by
                the Licensor of any of its rights, title or interest in or to
                the Software (including any Improvements) or any Deliverables or
                any of their respective Intellectual Property Rights.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee shall not attempt or purport to assign, transfer
                  or convey, whether gratuitously or otherwise, any title,
                  ownership or interest in or to the Software or any
                  Deliverables.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee has no right under this Agreement, and shall not
                  permit any third party, to create, develop or make any
                  Improvements. The Parties hereby agree that should any
                  Improvement be required in order to enable the exercise of the
                  Licensed Purpose, then that Improvement shall be developed
                  solely by the Licensor at its discretion, whether directly or
                  by a third party engaged by the Licensor. At all times, the
                  Licensor may, but shall not be under any obligation
                  whatsoever, to create, develop, make or provide any
                  Improvements that may be requested by the Licensee.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px">Export</span></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall not export, directly or indirectly, any
                    technical data acquired from the Licensor under this
                    Agreement (or any products, including software,
                    incorporating any such data) to any country for which the
                    government or any agency thereof at the time of export
                    requires an export licence or other governmental approval
                    without first obtaining such licence or
                    approval.&nbsp;</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee hereby undertakes:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >contractually to oblige any third party to whom it discloses or
                transfers any such data or products to make an undertaking to it
                in similar terms to that set out above; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if requested, to provide the Licensor with any reasonable
                assistance, at the reasonable cost of the Licensee, in order to
                enable the Licensor to perform any activity required by any
                competent government or agency in any relevant jurisdiction for
                the purpose of compliance with any applicable export control
                laws or regulations.</span
              >
            </p>
          </li>
        </ol>
        <ol start="9">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Warranties and Representations</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Each Party hereby represents and warrants to the other
                    Party that:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it has the legal right, and full power and authority, to
                execute and deliver this Agreement and to exercise all of its
                rights and to perform all of its respective obligations arising
                under or in connection with this Agreement;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Agreement, and all other documents to be executed by it
                under the terms hereof, shall, when executed, create legal,
                valid and binding obligations for that Party that will be
                enforceable in accordance with their terms;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it is not aware of any agreements, charges, actions, suits,
                proceedings, actual or threatened which may impair it from
                performing its obligations hereunder;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >all corporate action required of it in order to validly and
                duly authorise its execution and delivery of this Agreement,
                and/or its exercise of any rights and performance of any
                obligations arising under or in connection with this same
                Agreement, has been duly and validly taken by it; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the above representations and warranties will be true and
                accurate throughout the duration of this Agreement with
                reference to the facts and circumstances subsisting from time to
                time.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="2">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee hereby warrants, represents and undertakes to
                  the Licensor that it shall:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >use the Software for the Licensed Purpose alone and for no
                other purpose;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >not do, or omit to do, or permit to be done (including by any
                of its permitted sub-licensees), any act that will or may in any
                way:</span
              >
            </p>
          </li>
        </ol>
        <ol type="a">
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  >weaken, diminish, damage or be detrimental to&nbsp;</span
                ></span
              ><span lang="en-US"
                ><span style="font-style: normal">the Software; or</span></span
              >
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  >prejudice the validity or enforceability of, or the
                  Licensor&rsquo;s rights or ownership of any rights in or to,
                  any&nbsp;</span
                ></span
              ><span lang="en-US"
                ><span style="font-style: normal"
                  >Software or its Intellectual Property Rights;</span
                ></span
              >
            </h6>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >not use, or permit any third party to use, the Software in any
                way that infringes the Intellectual Property Rights or other
                rights of the Licensor or any third party;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >not, in connection with its exercise of any or all of the
                rights granted under this Agreement, contravene or breach any
                applicable law, statute, rule or regulation or any judgment,
                decree, order or permit to which the Licensee may be subject;
                and&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >not, in connection with its exercise of any or all of the
                rights granted under this Agreement, cause the Licensor to be in
                breach any applicable laws, statutes, rules, regulations or
                regulatory instruments to which the Licensor may be
                subject.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="3">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensor has fully informed the Licensee, and the
                  Licensee hereby acknowledges and accepts, that the Software
                  may contain errors. The Licensee accepts full responsibility
                  for its selection of the Software to achieve its desired
                  results, and further acknowledges that the Software has not
                  been customised or in any way developed to meet the individual
                  requirements of the Licensee and is being licensed here to the
                  Licensee on that basis.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >Further, the Parties hereby acknowledge and agree that the
                  Licensor neither warrants, nor represents, the following to
                  the Licensee (each of which are fully excluded by and between
                  the Parties to the maximum extent permitted by applicable
                  law):</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that the use of the Software will be uninterrupted or
                error-free or that any errors arising thereto will be corrected;
                or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that the Software will run properly on all or any particular
                hardware or computer systems; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that the Software will meet the needs or expectations of the
                Licensee or will operate in the combinations selected for use by
                the Licensee; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 0.17in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that the information or results obtained by or through its use
                of the Software will meet the requirements of the
                Licensee;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-top: 0.19in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            >each of which are hereby being fully excluded by and between the
            Parties to the maximum extent permitted by applicable law.</span
          >
        </p>
        <ol>
          <ol start="5">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensor is not responsible for any delays, delivery
                  failures, or any other loss or damage resulting from the
                  transfer of data over communications networks and facilities,
                  including the internet, or which otherwise arises due to any
                  failure or disruption of any internet services, and the
                  Licensee acknowledges and accepts that the that the operation
                  of the Software may be subject to limitations, delays and
                  other problems inherent in the use of such communications
                  facilities and/or reliance on internet services.&nbsp;</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee hereby acknowledges and accepts that the
                  Software may contain open-source software and that any such
                  open-source software is provided to the Licensee &ldquo;AS
                  IS&rdquo; and is expressly subject to all limitations and
                  restrictions in the applicable open-source licence.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee warrants and represents that:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it is permitted to use the Software in its own name and for its
                own account under any applicable regulation;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it has carefully assessed and understands the functionalities
                of the Software;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it will inform the Licensor immediately if it becomes aware
                that any aspect of the Software conflicts with any law, rule or
                regulation applicable to the Licensee;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it will not ask for any assistance or support by the Licensor
                which could conflict with any applicable law, rule or
                regulation; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >it has understood that, subject to the requirements of and its
                obligations under this Agreement, the Licensor will act upon the
                Licensee&rsquo;s instructions in its own time and in particular
                will not be liable for a delayed reaction.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The Licensee accepts and acknowledges that the Software is
                  provided &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo;,
                  and while the Licensor endeavours to use all reasonable skill
                  and care in undertaking its obligations in accordance with
                  this Agreement, Licensor does not (to the maximum extent
                  permitted by law) warrant, represent or otherwise undertake,
                  and nor does it assume any liability, in this regard that
                  either the basic functionality or their operation will be
                  uninterrupted, &ldquo;bug-free&rdquo; or error-free. This
                  disclaimer and limitation of liability encompasses all
                  components of the Software, irrespective of the server on
                  which they are run as well as any data that is exchanged
                  between these components including the failure of transmission
                  or the defective content of such data. Any expressed or
                  implied warranties, guarantees or conditions (whether by
                  statute or otherwise) of merchantability, fitness for a
                  particular purpose, title, defects (apparent or otherwise),
                  quality, non-infringement or non-misappropriation of
                  Intellectual Property Rights, custom, trade, quiet enjoyment,
                  accuracy or informational content or results or system
                  integration in regard to the Software are disclaimed in full.
                  In particular, no guarantee, warranty or assurance is provided
                  that the Software will identify the best trading options for
                  the Licensee.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >The provisions of this Clause (including all warranty
                  exclusions and disclaimers) shall apply&nbsp;</span
                ><span size="2" style="font-size: 13px"
                  ><em>mutatis mutandis</em></span
                ><span size="2" style="font-size: 13px"
                  >&nbsp;to the Licensee in respect of any and all Improvements
                  that may be subsequently licensed to it by the Licensor (at
                  the Licensor&rsquo;s discretion).</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Liability&nbsp;</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >All references to a &ldquo;</span
                  ><span size="2" style="font-size: 13px"
                    ><strong>Party</strong></span
                  ><span size="2" style="font-size: 13px"
                    >&rdquo; in this Clause 10 shall be construed as also
                    including all employees, officers, agents, contractors,
                    subcontractors and suppliers of that same Party.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee takes full and sole responsibility for (i) all
                    trading decisions effected by it from or in connection with
                    its use of the Software, (ii) the monitoring of all
                    components of the Software deployed on the Licensee&rsquo;s
                    systems, and (iii) the liability for all losses incurred
                    from its trading decision. The Licensee understands and
                    acknowledges the inherent risks associated with trading,
                    including the possibility of losses (which may be
                    substantial</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee acknowledges and accepts that it is solely
                    responsible for its systems, in particular, for their
                    integrity. The Licensor will not assume and hereby excludes
                    any liability caused by malfunction, insecurity, lack of
                    integrity of the Licensee&rsquo;s systems. Furthermore, the
                    Licensor does not assume any liability with regard to the
                    security of data transferred or data exchanged between the
                    components forming part of the Software</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Each Party&rsquo;s liability for any indirect,
                    consequential, exemplary or special damages is excluded in
                    full (including loss of profits, sales, revenue, business or
                    business opportunities, or loss of or damage to reputation
                    or goodwill, or loss of anticipated savings or wasted
                    expenditure, or loss of or corruption of software, data or
                    information). The Licensor shall not be liable for any loss
                    of data and/or any damages resulting in or caused by any
                    loss of data.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensor has fully informed the Licensee, who hereby
                    acknowledges and accepts that, from time to time, as a
                    result of hardware, software or technical failures or
                    outages, the Software (including its functionality) may be
                    temporarily disrupted or unavailable. The Licensor shall use
                    its reasonable endeavours, and shall take all reasonable
                    actions required, to reduce and minimise any such disruption
                    or unavailability. This said, the Licensee hereby
                    acknowledges and accepts that the Licensor shall not under
                    any circumstance be liable, or in any way responsible, for
                    any direct, special, indirect, consequential, punitive or
                    exemplary damages, including damages for loss of profits,
                    revenue, goodwill, business opportunities or lost earnings,
                    that may be suffered or incurred by the Licensee as a result
                    of or in connection with (i) any downtime or disruptions to
                    the Software and/or (ii) any period of unavailability of the
                    Software (or any part thereof). Any and all such liability
                    is hereby excluded in full by and between them.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee assume sole responsibility for: (i) the
                    results obtained from its use of the Software, (ii) the
                    conclusions drawn from such use, and (iii) all decisions,
                    including trading decisions, taken by the Licensee based on
                    those results and/or conclusions. The Licensor shall have no
                    liability for any damage caused by errors or omissions in
                    any of the Licensee&rsquo;s Parameters.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >In addition, the Licensee hereby agrees that, in entering
                    into this&nbsp;Agreement, either it did not rely on any
                    representations (whether written or oral) of any kind or of
                    any person other than those expressly set out in
                    this&nbsp;Agreement&nbsp;or (if it did rely on any
                    representations, whether written or oral, not expressly set
                    out in this&nbsp;Agreement) that it shall have no remedy in
                    respect of such representations and (in either case) the
                    Licensor shall have no liability in any circumstances
                    otherwise than in accordance with the express terms of this
                    Agreement.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Without any prejudice to the foregoing, the Licensor shall
                    only be liable to the Licensee for those direct damages that
                    are sustained by the Licensee and which directly arise out
                    of or relate to the Licensor&rsquo;s breach of this
                    Agreement, provided that under all circumstances the
                    Licensor&rsquo;s liability to the Licensee (including any
                    person claiming under or through it), whether in contract,
                    tort (including negligence), shall not exceed, in the
                    aggregate, the amount of 1.000euros. This shall constitute
                    the entirety and limit of the Licensor&rsquo;s potential
                    liability to the Licensee under this Agreement.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Nothing in this Agreement shall have the effect of
                    excluding or limiting any liability for:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >death or personal injury caused by a Party&rsquo;s gross
                negligence or that of its officers, employees, representatives,
                contractors, sub-contractors or agents;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >fraud or fraudulent misrepresentation; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any other liability which may not be excluded by law.</span
              >
            </p>
          </li>
        </ol>
        <ol start="11">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px">Indemnity</span></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall and hereby undertakes to defend,
                    indemnify and hold the Licensor harmless from and against
                    all liabilities, costs, expenses, damages and losses
                    (including any direct, indirect or consequential losses,
                    loss of profit, loss of reputation and all interest,
                    penalties and legal costs and all professional costs and
                    expenses) suffered or incurred by the Licensor, or for which
                    the Licensor may become liable, as a result of or in
                    connection with:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee&rsquo;s:</span
              >
            </p>
          </li>
        </ol>
        <ol type="a">
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span style="font-style: normal"
                >exercise of any of the rights granted to it under this
                Agreement;</span
              >
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span style="font-style: normal"
                >breach or negligent performance or non-performance of this
                Agreement; or</span
              >
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span style="font-style: normal"
                >breach or default of any of any or all of its representations
                or warranties givenby it under this Agreement; or</span
              >
            </h6>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the enforcement by the Licensor of this Agreement; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any claim threatened or made against the Licensor for actual or
                alleged infringement of a third party&rsquo;s Intellectual
                Property Rights arising out of, or in connection with, the
                Licensee or any permitted sub-licensee&rsquo;s use of the
                Software (or any part thereof).</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >If a payment due from a Party under this Clause is subject to
                  tax (whether by way of direct assessment or withholding at its
                  source), the other Party shall be entitled to receive from the
                  first Party such amounts as shall ensure that the net receipt,
                  after tax, to the Licensor in respect of the payment is the
                  same as it would have been were the payment not subject to
                  tax.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Protection of Proprietary Rights</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensee shall immediately notify the Licensor in
                    writing, giving full particulars, if any of the following
                    matters come to its attention:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any actual, suspected or threatened infringement of the
                Software;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any claim, whether made or threatened, that the Software
                infringes the rights of any third party; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >any other form of attack, challenge, charge or claim to which
                the Software may become subject.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="2">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >In respect of any of the matters listed in Clause 12.1, the
                  Parties hereby agree:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensor shall have absolute discretion on what action to
                take, if any;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensor shall have exclusive control over the conduct of
                any and all claims, proceedings, and litigation;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee shall not make any admissions (whether in terms of
                liability, agreement, compromise or otherwise) other than to the
                Licensor and shall provide the Licensor with all assistance that
                the Licensor may require in its handling of the matter;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee shall give the Licensor and its professional
                advisors access at reasonable times (on reasonable prior notice)
                to its premises and its officers, directors, employees and
                representatives, and to any relevant assets, accounts, documents
                and records within its power or control, so as to enable the
                Licensor and its advisors to examine them and to take copies (at
                the Licensor&rsquo;s expense) for the purpose of assessing or
                evaluating any such claim or infringement; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensor shall bear the cost of any action or proceedings
                filed or taken in connection thereto and shall be entitled to
                retain all sums that may be recovered or awarded in its favour
                in any such action or proceedings for its own account.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="3">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >If any infringement claim is made against the Software, or in
                  Licensor&rsquo;s reasonable opinion is likely to be made, the
                  Licensor may at its sole choice:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >obtain for the Licensee the right to continue using the
                Software in the manner permitted under this
                Agreement;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if the Licensor is unable to carry out the step described in
                clause 12.3(i), modify or replace the infringing part of the
                Software so as to avoid the infringement or alleged
                infringement, without materially diminishing its utility,
                functionality or value; or</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if the Licensor is unable to carry out the steps described
                above in paras (i) or (ii), then the Licensor may require the
                Licensee to cease using the Software and terminate this
                Agreement forthwith, provided it gives the Licensee sufficient
                notice to allow the Licensee to migrate from, or cease using,
                the Software.</span
              >
            </p>
          </li>
        </ol>
        <ol start="13">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Term and Termination</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >This Agreement (including the Licence) shall come into
                    effect as of the Commencement Date and shall continue in
                    full force and effect for an indefinite term, except and
                    unless terminated by either Party in accordance with the
                    provisions of this Clause 13.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Without prejudice to any rights that have accrued under
                    this Agreement or any other rights or remedies, either Party
                    (the &ldquo;</span
                  ><span size="2" style="font-size: 13px"
                    ><strong>Terminating Party</strong></span
                  ><span size="2" style="font-size: 13px"
                    >&rdquo;) may at any time terminate this Agreement with
                    immediate effect by giving written notice to the other Party
                    if:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that other Party commits a material breach of any of its
                obligations under or in relation to this Agreement and which
                breach is irremediable, or (if such breach is remediable) fails
                to remedy that breach within a period of 30 days after being
                notified in writing to do so;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.98in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that other Party suspends or ceases, or threatens to suspend or
                cease, to carry on all or a substantial part of its
                business;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.98in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that other Party suspends, or threatens to suspend, payment of
                its debts or is unable to pay its debts as they fall due, or
                admits inability to pay its debts or is deemed either unable to
                pay its debts or as otherwise having no reasonable prospect of
                so doing;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that other Party is the subject of a winding up order, whether
                compulsory or voluntary, or becomes insolvent, or becomes the
                subject of an administration order, or enters into any
                composition or arrangement with creditors, or has a receiver
                appointed over the whole or any substantial part of its
                assets;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >that other Party becomes subject to litigation, regulatory
                action or enforcement proceedings as a result of or in
                connection with its activities under this Agreement and whereby
                such proceedings are likely to have a material adverse effect on
                that other Party&rsquo;s ability to fulfil its duties or perform
                its obligations under this Agreement hereto.&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol start="3">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >For the purposes of Clause 13.2 above, the term &ldquo;</span
                ><span size="2" style="font-size: 13px"
                  ><strong>material breach</strong></span
                ><span size="2" style="font-size: 13px">&rdquo;</span
                ><span size="2" style="font-size: 13px"
                  >&nbsp;means a breach by a Party (including an anticipatory
                  breach) that is serious in the widest sense of having a
                  serious effect on the benefit which the other Party would
                  otherwise derive from a substantial portion of this Agreement,
                  and shall (without limitation) include:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >in the case of the Licensee, any breach by the Licensee of any
                or all of its obligations under Clause (&ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><strong>Licence Grant</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo;), Clause 7 (&ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><strong>Ownership and Improvements</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo;), Clause (&ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><strong>Export</strong></span
              ><span size="2" style="font-size: 13px"
                >&rdquo;) and/or Clause (&ldquo;</span
              ><span size="2" style="font-size: 13px"
                ><strong>Protection of Proprietary Rights</strong></span
              ><span size="2" style="font-size: 13px">&rdquo;); and</span>
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >in the case of either Party, any breach or default by a Party
                of:</span
              >
            </p>
          </li>
        </ol>
        <ol type="a">
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  >any or all of its obligations under Clause&nbsp;</span
                ></span
              ><span size="2" style="font-size: 13px"
                ><span style="font-style: normal">6</span></span
              ><span size="2" style="font-size: 13px"
                ><span style="font-style: normal">&nbsp;(&ldquo;</span></span
              ><span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  ><strong>Confidentiality</strong></span
                ></span
              ><span style="font-style: normal">&rdquo;); or</span>
            </h6>
          </li>
          <li>
            <h6
              style="
                color: #000000;
                line-height: 115%;
                margin-top: 0.14in;
                margin-bottom: 0in;
                background: transparent;
                font-family: 'Cambria', serif;
                font-size: 16px;
                font-style: italic;
              "
            >
              <span size="2" style="font-size: 13px"
                ><span style="font-style: normal"
                  >any or all of its representations or warranties given by it
                  under this Agreement,</span
                ></span
              ><span style="font-style: normal"
                >including those made under Clause .</span
              >
            </h6>
          </li>
        </ol>
        <ol>
          <ol start="4">
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >In addition to Clause , the Licensor shall also have the
                  right to terminate this Agreement with immediate effect by
                  giving written notice to the Licensee if:</span
                >
              </p>
            </li>
          </ol>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee is in breach of the Licensed Purpose and fails to
                remedy that breach within a period of five (5) days after having
                been notified to do so by the Licensor;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensee fails to pay any amount due by it to the Licensor
                under this Agreement on the due date for payment and remains in
                default for not less than ten (10) days after having been
                notified by the Licensor to make such payment;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the Licensor is ordered or required by any Regulatory Authority
                to terminate its relationship with the Licensee or this
                Agreement;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >the applicable regulation or the practice of any Regulatory
                Authority requires the termination of the licence or provision
                of the Software to the Licensee, as defined within this
                Agreement. Termination on this ground shall be valid if the
                either party would no longer be compliant with that applicable
                regulation.</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  margin-bottom: 0.08in;
                  background: transparent;
                  line-height: 115%;
                  margin-top: 0.19in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >Without prejudice to the foregoing, the Licensee may
                  terminate this Agreement at any time without need for cause,
                  while the Licensor may terminate this Agreement without need
                  for cause on giving the Licensee one (1) day prior notice in
                  writing.&nbsp;</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Survival and Effects of Termination</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Any termination of this Agreement pursuant to clause 11 is
                    not intended to be a waiver of, and shall be without
                    prejudice to any existing, accrued or additional rights or
                    remedies the Parties may be entitled to hereunder or at law,
                    statute or otherwise.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Termination of this Agreement shall not affect any of the
                    rights, remedies, obligations or liabilities of the Parties
                    which have accrued up to the date of termination, including
                    the right to sue for overdue sums and the right to claim
                    damages in respect of any breach of this Agreement, which
                    exists or existed as at or before the said date of
                    termination.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 0.21in;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >On termination of this Agreement for whatever reason and
                    subject to any express provisions set out elsewhere in this
                    Agreement, the Licensee shall:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.6in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >have no right to keep or use, and shall not keep or use, any
                Deliverable (including copies) and shall immediately return the
                same to the Licensor;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >cease using the Software;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >cease using the Deliverables or any part thereof;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="4">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >remove the Software and disable all connection to it from its
                systems; and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="5">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >return to the Licensor or delete, uninstall and/or destroy (at
                the Licensor&apos;s direction) any Deliverables, as directed by
                the Licensor; and</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 0.21in;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >On request by the Licensor, the Licensee will provide a
                  certificate signed by the Licensee that it has complied with
                  its obligations under this clause.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 0.21in;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >Any provision of this Agreement that expressly or by
                  implication is intended to come into or continue in force on
                  or after termination or expiry of this Agreement, including
                  but not limited to Clauses 6 (</span
                ><span size="2" style="font-size: 13px"
                  ><strong>Confidentiality</strong></span
                ><span size="2" style="font-size: 13px">), Clause 7 (</span
                ><span size="2" style="font-size: 13px"
                  ><strong>IP Ownership</strong></span
                ><span size="2" style="font-size: 13px">), Clause 9 (</span
                ><span size="2" style="font-size: 13px"
                  ><strong>Liability</strong></span
                ><span size="2" style="font-size: 13px"
                  >) and this Clause 11 (</span
                ><span size="2" style="font-size: 13px"
                  ><strong>Consequences of Termination</strong></span
                ><span size="2" style="font-size: 13px"
                  >), shall remain in full force and effect.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Miscellaneous</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >This Agreement contains the whole agreement between the
                    Parties and supersedes all previous agreements, promises,
                    assurances, warranties, representations and understandings
                    between them, whether written or oral, relating to its
                    subject matter.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Each Party acknowledges that, in entering into this
                    Agreement, it has not relied on, and shall have no right or
                    remedy in respect of, any statement, representation,
                    assurance or warranty other than as expressly set out in
                    this agreement.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Nothing in this Agreement is intended to, or shall be
                    deemed to, establish any partnership or joint venture
                    between the Parties, or constitute any Party the agent of
                    the other, or authorise any Party to make or enter into any
                    commitments for or on behalf of the other.&nbsp;</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Licensor shall be entitled to amend the terms and
                    conditions of this Agreement at any time. Changes which are
                    not in the Licensee&rsquo;s favour may take place at any
                    time, by giving notice to the Licensee at least two (2)
                    weeks in advance. The Licensee shall be responsible for
                    reviewing and familiarising itself with any changes made. In
                    the event that the Licensee does not accept such changes,
                    the Licensee must without delay and in any case prior to
                    entry into force of such changes, notify Licensor that it
                    does not accept them. Notification thereof must be made to
                    the Licensor in writing within two weeks of receiving the
                    notification of amendment. In the absence of such
                    notification, those amendments shall be deemed to have been
                    unconditionally accepted by the Licensee.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >No failure or delay by either Party in exercising any
                    rights, power or legal remedy available to it herein shall
                    operate as a waiver thereof. A waiver by a Party of any
                    right or remedy under this Agreement or by law is only
                    effective if given in writing and shall not be deemed a
                    waiver of any subsequent right or remedy.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >If any provision, or part of any provision, of this
                    Agreement is found by any court or other authority of
                    competent jurisdiction to be invalid, illegal or
                    unenforceable, that provision or part provision shall be
                    deemed modified to the minimum extent necessary to make it
                    valid, legal and enforceable. If such modification is not
                    possible, the provision or part-provision shall be deemed
                    not to form part of this Agreement, but the validity and
                    enforceability of the other provisions of this Agreement
                    shall not be affected and shall remain in effect.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Neither Party shall be in breach of this Agreement, nor
                    liable for any delay in performing, or failure to perform,
                    any of its duties or obligations under this Agreement if
                    such delay or failure arises from any event, circumstance or
                    cause beyond its reasonable control (including any fire or
                    other casualty, act of God, drought, flood, natural
                    disaster, terrorist attack, epidemic, strike or labour
                    dispute, war, interruption or failure of utility service,
                    service outage, change in law or any order or requirement
                    of, or action taken by, any Regulatory Authority). In such
                    circumstances, the Party affected by the force majeure event
                    (hereinafter, the &ldquo;</span
                  ><span size="2" style="font-size: 13px"
                    ><strong>affected Party</strong></span
                  ><span size="2" style="font-size: 13px"
                    >&rdquo;) shall be entitled to a reasonable extension of
                    time in order to perform its affected duties or obligations.
                    If, however, the period of delay, failure or non-performance
                    continues for over two (2) months, the other Party may
                    terminate this Agreement by giving fourteen (14) days
                    written notice to the affected Party.</span
                  >
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Notices and Communications</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    margin-bottom: 0in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >Any communication to be given in connection with the
                    Agreement shall be in writing and in English and shall
                    either be delivered by hand or sent by registered or
                    recorded post, fax or email. The contact details shall be
                    such details provided by each Party to the other Party from
                    time to time. Any notice or communication shall be deemed to
                    have been received:</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-right: 0.25in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if delivered by hand, or at the time the notice is left at the
                proper address;&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-right: 0.25in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if sent by pre-paid first-class post or other next working day
                delivery service, at the time recorded by the delivery service;
                and</span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 1in;
            margin-right: 0.25in;
            margin-bottom: 0in;
            text-align: justify;
          "
        >
          <br />
        </p>
        <ol type="i" start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-bottom: 0in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                >if sent by fax or email, at the time of transmission, or, if
                this time falls outside business hours in the place of receipt,
                when business hours resume. In this clause, business hours means
                9.00am to 5.00pm Monday to Friday on a day that is not a public
                holiday in the place of receipt.&nbsp;</span
              >
            </p>
          </li>
        </ol>
        <ol>
          <ol>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-right: 0.25in;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >Any notices of a change of address, telephone number or fax
                  number shall be effective only upon receipt of notification by
                  the other Party to the Agreement.</span
                >
              </p>
            </li>
            <li>
              <p
                style="
                  color: rgb(0, 0, 0);
                  background: transparent;
                  line-height: 115%;
                  margin-right: 0.25in;
                  margin-top: 0.19in;
                  margin-bottom: 0in;
                  text-align: justify;
                "
              >
                <span size="2" style="font-size: 13px"
                  >This clause does not apply to the service of any proceedings
                  or other documents in any legal or judicial action or, where
                  applicable, any arbitration or other method of dispute
                  resolution.</span
                >
              </p>
            </li>
          </ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-right: 0.25in;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Governing Law and Arbitration</span
                ></strong
              >
            </p>
            <ol>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >This Agreement, including its construction, validity and
                    performance, and any dispute or claim arising out of or in
                    connection with it or its subject matter or formation
                    (including any non-contractual disputes or claims) shall be
                    exclusively governed by and construed in all respects in
                    accordance with the laws of Malta.</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >The Parties shall make every effort to resolve any dispute,
                    claim or controversy arising out of or in connection with
                    this Agreement through good faith negotiation. If the same
                    cannot be resolved by negotiation, the Parties agree that
                    any dispute, controversy or claim arising out of, or
                    relating to, or concerning, this Agreement, or the breach,
                    or invalidity thereof, shall be referred to and decided
                    solely and exclusively by Arbitration in Malta in accordance
                    with the Malta Arbitration Act, Chapter 387 of the laws of
                    Malta, and the Arbitration Rules of the Malta Arbitration
                    Centre as at present in force, which rules are deemed to be
                    incorporated by reference into this clause. The number of
                    arbitrators shall be three (3). Each Party shall, within
                    fifteen (15) days from being served with a notice of
                    arbitration, be entitled to appoint an arbitrator and the
                    third arbitrator, who will act as Chairman of the panel,
                    shall be selected by the mutual accord of the said two
                    appointed arbitrators. Should a Party fail to appoint an
                    arbitrator within the period of fifteen (15) days, the Malta
                    Centre for Arbitration shall appoint that arbitrator at its
                    own discretion. The place and seat of arbitration shall be
                    Malta and the language to be used shall be English. The
                    applicable substantive law shall be the laws of Malta and
                    the arbitration panel shall have the power to
                    decide&nbsp;</span
                  ><span size="2" style="font-size: 13px"
                    ><em>ex aequo</em></span
                  ><span size="2" style="font-size: 13px"
                    >&nbsp;et bono. The award shall be final and binding upon
                    the Parties, and no appeal shall lie thereto.&nbsp;</span
                  >
                </p>
              </li>
              <li>
                <p
                  style="
                    color: rgb(0, 0, 0);
                    margin-bottom: 0.08in;
                    background: transparent;
                    line-height: 115%;
                    margin-right: 0.25in;
                    margin-top: 0.19in;
                    text-align: justify;
                  "
                >
                  <span size="2" style="font-size: 13px"
                    >In case this Agreement or any part of it is assigned or
                    transferred to a third party, such third party shall
                    automatically be bound by the provisions of this arbitration
                    clause.</span
                  >
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                ><strong>SOFTWARE</strong></span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            line-height: 115%;
            background: transparent;
            font-family: Arial, sans-serif;
            font-size: 15px;
            margin-bottom: 0.14in;
            text-align: justify;
          "
        >
          The Software constitutes a BOT which is a typical SaaS, or software as
          a Service, that is designed to act in one exchange account of the
          Licensee through the API (Application Protocol Interface).
        </p>
        <ul>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              When a user registers and verifies his/her e-mail account directly
              from the browser, the software will open section reserved to the
              Licensee directly in the cloud.
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              The Licensee has to open an exchange account and insert the API
              KEY and the API SECRET KEY to the software to allow it to trade in
              the name of the Licensee.
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              In this reserved area, accessible only by the Licensee, there is
              the trading BOT developed by the Licensor with the aim to
              eliminate the human emotion element in trading decisions.
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              The BOT executes a daily analysis of data which the Licensee may
              then use to assess whether to invest or not for any single asset
              identified by it (and, if so, to execute the necessary orders).
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              The BOT also records all trades made in order to let the Licensee
              know the P/L and the daily position of any assets.
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              The BOT also calculates the Performance accrued for the
              performance fees calculation that the Licensee agree to pay based
              on the calculation selected.
            </p>
          </li>
        </ul>
        <p
          style="
            color: rgb(0, 0, 0);
            margin: 0.19in 0in 0.08in 0.1in;
            background: transparent;
            line-height: 115%;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            >The final decisions about when and how to trade are taken alone by
            the Licensee. The Licensee is at all times solely responsible for
            all of its trading decisions.</span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            margin: 0.19in 0in 0.08in 0.1in;
            background: transparent;
            line-height: 115%;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            >The Licensor may at its sole discretion but upon notice to the
            Licensee, adapt or change the specifications of the software
            components mentioned above.</span
          >
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <p
          style="
            color: #000000;
            line-height: 115%;
            margin-bottom: 0.17in;
            background: transparent;
            margin-top: 0.17in;
          "
        >
          <br />&nbsp;
        </p>
        <ol start="2">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <span size="2" style="font-size: 13px"
                ><strong>FEES</strong></span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >The Licence Fee to be paid by the Licensee to the Licensor for
              the provision of the Software shall be divided into subscription
              fees and performance fees as outlined below based on the type of
              software request.</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <strong
            ><span size="2" style="font-size: 13px"><u>SmartDCA</u></span
            ><sup
              ><span size="2" style="font-size: 13px"><u>TM</u></span></sup
            ><span size="2" style="font-size: 13px"
              ><u>&nbsp;BOT</u></span
            ></strong
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >There are no fees to be paid by the Licensee to the Licensor for
              the provision of the software because the Licensor has an
              agreement for revenues share in place with the Exchange
              platform.</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <strong
            ><span size="2" style="font-size: 13px"
              ><u>Target 25% Trading BOT&nbsp;</u></span
            ></strong
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><em><strong>Subscription Fees</strong></em></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >The subscription fees can be of three different types:</span
            ></span
          >
        </p>
        <ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px">One asset</span></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&nbsp;(Bitcoin): the monthly subscription fee shall be of
                  USD$ 7;&nbsp;</span
                ></span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Three assets</span
                ></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&nbsp;(Bitcoin, Ethereum and one other chosen by the
                  licensee): the monthly subscription shall be of USD$ 14;</span
                ></span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Five assets</span
                ></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&nbsp;(Bitcoin, Ethereum and three other chosen by the
                  licensee): the monthly subscription fee shall be of USD$
                  24;</span
                ></span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >The monthly subscription fees can be paid by credit or debit
              card.</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >If the Licensee will switch from one plan to the other, the new
              subscription fees will be apply from the day of changes, and the
              day for accruing the monthly fees will be update
              accordingly.</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.25in;
            text-indent: -0.25in;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><em><strong>Performance Fees</strong></em></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >The Licensee has an option to select one of the performance fee
              models below, which option shall be notified to the Licensor in
              writing as at the Commencement Date:</span
            ></span
          >
        </p>
        <ol>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >Target 25%:</span
                ></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&nbsp;under this model, the Licensee shall not pay a
                  performance fee until the total asset owned by the Licensee
                  (the &ldquo;</span
                ></span
              ><strong
                ><span size="2" style="font-size: 13px">Asset</span></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&rdquo;) has appreciated by thirty per cent (30%) gross (the
                  &ldquo;</span
                ></span
              ><strong
                ><span size="2" style="font-size: 13px">Target</span></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >&rdquo;) from the value of the Asset as at the Commencement
                  Date. Once the Target is met, the Software shall cease
                  operating, liquidate the Asset, inform the Licensee that the
                  Target has been met and pay five per cent (5%) of the value of
                  the Asset to the Licensor;</span
                ></span
              >
            </p>
          </li>
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                background: transparent;
                line-height: 115%;
                margin-top: 0.17in;
                margin-bottom: 0.17in;
                text-align: justify;
              "
            >
              <strong
                ><span size="2" style="font-size: 13px"
                  >High Watermark 15% performance fee</span
                ></strong
              ><span size="2" style="font-size: 13px"
                ><span style="font-weight: normal"
                  >: under this model, the Licensee shall pay the Licensor a
                  monthly performance fee of fifteen per cent (15%) of the
                  appreciation in value of the Asset over the previous High
                  Watermark (&ldquo;HWM&rdquo;), calculated at the end of each
                  calendar month.</span
                ></span
              >
            </p>
          </li>
        </ol>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >For the purposes of this section entitled &ldquo;Performance
              Fees&rdquo;, High Watermark or HWM shall mean the higher of (a)
              the value of the Asset as at the Commencement Date and (b) the
              highest value of the Asset on which a performance fee was
              paid.&nbsp;</span
            ></span
          >
        </p>
        <p
          style="
            color: rgb(0, 0, 0);
            background: transparent;
            line-height: 115%;
            margin-left: 0.5in;
            margin-top: 0.17in;
            margin-bottom: 0.17in;
            text-align: justify;
          "
        >
          <span size="2" style="font-size: 13px"
            ><span style="font-weight: normal"
              >Under this model the Licensee is required to provide the API the
              right to withdraw the performance fee from the account of the
              Licensee automatically on a monthly basis.&nbsp;</span
            ></span
          >
        </p>
        <ol start="3">
          <li>
            <p
              style="
                color: rgb(0, 0, 0);
                line-height: 115%;
                background: transparent;
                font-family: Arial, sans-serif;
                font-size: 15px;
                margin-bottom: 0.14in;
                text-align: justify;
              "
            >
              <span lang="en-US"
                ><strong>High Watermark 10% performance fee</strong></span
              >:
              <span lang="en-US"
                >under this model, the Licensee shall enter into a smart
                contract with the Licensor which regulates that the Licensee
                shall pay the Licensor a monthly performance fee of ten per cent
                (10%) of the appreciation in value of the Asset over the
                previous High Watermark.</span
              >
            </p>
          </li>
        </ol>
      </div>

      <mat-checkbox class="example-margin" [(ngModel)]="checked" color="primary"
        >{{"step4.agreeOnTnc" | translate}}</mat-checkbox
      >
    </div>
    <div class="bottom">
      <button
        (click)="continue()"
        [disabled]="!checked"
        mat-raised-button
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{ "continue" | translate}}
      </button>
    </div>
    <div class="flex-center">
      <img class="logo" src="assets/dw-logo.png" />
    </div>
  </div>
</div>
