export class Registration {
  amounts!: IAmounts;
  frequency!: number;
  cryptoPairs!: string[];
  userFirstName!: string;
  userLastName!: string;
  apiKey!: string;
  apiSecret!: string;
  email!: string;
  phoneNumber!: string;
  userId!: string;

  constructor() {}
}

export interface IAmounts {
  initialAmount: number;
  topUpAmount: number;
}

export interface IExchangeData {
  user: string;
  public_key: string;
  secret_key: string;
}

export enum STATUS {
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

export enum IFRAME_MESSAGES {
  SMART_DCA_RESULT = 'SMART_DCA_RESULT',
  SMART_DCA_CREDENTIALS_REQUEST = 'SMART_DCA_CREDENTIALS_REQUEST',
  SMART_DCA_CREDENTIALS_RESPONSE = 'SMART_DCA_CREDENTIALS_RESPONSE'
}
