import { Component, Input } from '@angular/core';
import { IFRAME_MESSAGES, STATUS } from 'src/app/models/registration.model';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss'],
})
export class FinalStepComponent {
  @Input() success = true;
  @Input() description = 'You have successfully activated the bot!';

  close() {
    try {
      window?.opener?.postMessage(
        JSON.stringify({
          event: IFRAME_MESSAGES.SMART_DCA_RESULT,
          data: {
            status: this.success ? STATUS.SUCCESS : STATUS.FAILED,
            message: this.description,
          },
        }),
        '*'
      );
    } catch (e) {
      try {
        window?.parent?.postMessage(
          JSON.stringify({
            event: IFRAME_MESSAGES.SMART_DCA_RESULT,
            data: {
              status: this.success ? STATUS.SUCCESS : STATUS.FAILED,
              message: this.description,
            },
          }),
          '*'
        );
      } catch (e) {
        throw Error(`[finalStep] Can't Use window postMessage - not avaliable`);
      }
    }
  }
}
