<notifier-container></notifier-container>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<mat-icon
  *ngIf="currentStep != 1 && currentStep != 6"
  class="back-button"
  aria-hidden="false"
  aria-label="Go back"
  fontIcon="reply"
  (click)="goToPreviousStep()"
  >reply</mat-icon
>

<mat-form-field class="lang-selector"
  ><img class="lang-ico" src="assets/icons/{{ selectedLanguage }}.png" />
  <mat-label class="lang-ico">Language</mat-label>
  <mat-select
    [(ngModel)]="selectedLanguage"
    (selectionChange)="languageChange(selectedLanguage)"
  >
    <mat-option *ngFor="let lang of languages" [value]="lang.id">
      <img class="lang-ico" src="assets/icons/{{ lang.id }}.png" />{{
        lang.name
      }}
    </mat-option>
  </mat-select>
</mat-form-field>

<app-step1-amounts
  *ngIf="currentStep == 1"
  (amountsEvent)="setAmounts($event)"
></app-step1-amounts>
<app-step2-frequency
  *ngIf="currentStep == 2"
  (frequencyEvent)="setFrequency($event)"
></app-step2-frequency>
<app-step3-crypto-pairs
  *ngIf="currentStep == 3"
  (setPairsEvent)="setPairs($event)"
></app-step3-crypto-pairs>
<app-step4-tnc
  *ngIf="currentStep == 4"
  (spinnerEvent)="spinnerEvent($event)"
  (tncContinueEvent)="handleUserDada($event)"
></app-step4-tnc>
<app-otp-confirmation
  *ngIf="currentStep == 5"
  (statusEvent)="otpVerified($event)"
  (spinnerEvent)="spinnerEvent($event)"
></app-otp-confirmation>
<app-final-step
  *ngIf="currentStep == 6"
  [success]="success"
  [description]="description"
></app-final-step>
