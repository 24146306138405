<div class="registration-form">
  <div class="flex-box">
    <div class="upper" *ngIf="frequencyOptions">
      <h1 class="title">{{ "frequency" | translate }}</h1>
      <mat-radio-group
        aria-labelledby="radio-group-label"
        class="radio-group"
        [(ngModel)]="selectedFrequency"
      >
        <mat-radio-button
          color="primary"
          class="radio-button no-wrap"
          *ngFor="let frequency of frequencyOptions"
          [value]="frequency"
        >
          <!-- <div class="flex-box"></div> -->

          <p class="frequency-title">
            <strong>{{ frequency.title }}</strong>
          </p>
          <mat-label>{{ frequency.description }}</mat-label>
          <!-- </div> -->
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="bottom">
      <button
        [disabled]="!selectedFrequency"
        mat-raised-button
        (click)="continue()"
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{ "continue" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="flex-center">
  <img class="logo" src="assets/dw-logo.png" />
</div>
