<notifier-container></notifier-container>

<div class="registration-form">
  <div class="flex-box">
    <div class="upper">
      <h1>{{ "step5.verification" | translate }}</h1>
      <p>{{ "step5.description" | translate }}</p>

      <form #f="ngForm" [formGroup]="phoneForm">
        <div class="wrapper">
          <ngx-intl-tel-input
            [cssClass]="'phone-number'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Italy"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National"
            name="phone"
            formControlName="phone"
          >
          </ngx-intl-tel-input>
        </div>
      </form>

      <!-- <mat-form-field class="phone full-width">
        <mat-label>{{ "step5.phone" | translate }}</mat-label>
        <input
          id="phoneInput"
          [disabled]="waitingForOtp"
          matInput
          pattern="^[+]\d{8,}$"
          placeholder="Ex. +39556888777"
          type="text"
          #phoneInput="ngModel"
          [(ngModel)]="phoneNumber"
        />
        <button
          [disabled]="waitingForOtp || phoneInput.invalid"
          *ngIf="phoneNumber"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="phoneNumber = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field> -->

      <div *ngIf="!error" class="flex-center">
        <button
          (click)="getOTP()"
          [disabled]="phoneForm.invalid"
          mat-raised-button
          color="primary"
          type="submit"
          class="full-width submit-button"
        >
          {{ "step5.getCode" | translate }}
        </button>
      </div>
      <div *ngIf="error" class="flex-center">
        <button
          (click)="getOTP()"
          [disabled]="phoneForm.invalid"
          mat-raised-button
          color="primary"
          type="submit"
          class="full-width submit-button"
        >
          <mat-icon>refresh</mat-icon> {{ "step5.resendCode" | translate }}
        </button>
      </div>

      <p *ngIf="otpSent">
        {{ "step5.smsSent" | translate }}
      </p>
      <div *ngIf="waitingForOtp" class="otp-center">
        <ng-otp-input
          (onInputChange)="onOtpChange($event)"
          [config]="{ length: 6 }"
        ></ng-otp-input>
      </div>
      <div id="sign-in-button"></div>
    </div>
    <div class="bottom">
      <button
        (click)="verifyOTP()"
        [disabled]="otp == undefined || otp.length !== 6"
        mat-raised-button
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{ "step5.verify" | translate }}
      </button>
    </div>
    <div class="flex-center">
      <img class="logo" src="assets/dw-logo.png" />
    </div>
  </div>
</div>
