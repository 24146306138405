export const environment = {
  isDev: false,
  finalStepNumber: 6,
  firebase: {
    projectId: 'websites-253911',
    appId: '1:478577969865:web:d37be5004eb6a788585b18',
    databaseURL: 'https://websites-253911.firebaseio.com',
    storageBucket: 'websites-253911.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDaCRuMJTqTaHeIUM1UoZG9ZZ0tJGhDZqo',
    authDomain: 'websites-253911.firebaseapp.com',
    messagingSenderId: '478577969865',
    measurementId: 'G-C4P64PHNEZ',
  },
};
