import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-otp-confirmation',
  templateUrl: './otp-confirmation.component.html',
  styleUrls: ['./otp-confirmation.component.scss'],
})
export class OtpConfirmationComponent {
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Italy, CountryISO.Malta];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });

  @Output() statusEvent = new EventEmitter<{
    uid: string;
    phoneNumber: string;
  }>();

  @Output() spinnerEvent = new EventEmitter<boolean>();

  otp!: string;
  verificationId: any;
  otpSent = false;
  recaptchaWidgetId: any;
  error: any;

  // verificationId!: string;

  constructor(private notifierService: NotifierService) {}

  onOtpChange(otp: string) {
    this.otp = otp;
  }

  verifyOTP() {
    this.spinnerEvent.emit(true);

    var credential = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      this.otp
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {

        try {
          localStorage.setItem('user_data', JSON.stringify(response));
        } catch (e) {
          console.error('Can not set the local storage in this environment');
        }

        let stringifiedResponse = JSON.stringify(response);
        let jsonex = JSON.parse(stringifiedResponse);

        const phoneNumber: any = this.phoneForm?.get('phone')?.value;
        if (phoneNumber.e164Number) {
          this.statusEvent.emit({
            uid: jsonex.user.uid,
            phoneNumber: phoneNumber.e164Number,
          });
        }
      })
      .catch((error) => {
        this.spinnerEvent.emit(false);
        console.error('verify error', error);
        this.notifierService.notify(
          'error',
          'Code provided seems incorrect. Please resend the code to your mobile.'
        );
        this.error = true;
      });
  }

  reCaptchaVerifier!: any;
  waitingForOtp = false;

  /**
   * get OPT code from firebase
   */
  getOTP() {
    this.spinnerEvent.emit(true);
    this.waitingForOtp = true;
    if (!this.reCaptchaVerifier)
      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
        }
      );

    const phoneNumber: any = this.phoneForm.get('phone')?.value;

    if (phoneNumber?.e164Number)
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber.e164Number, this.reCaptchaVerifier)
        .then((confirmationResult) => {
          this.spinnerEvent.emit(false);

          this.verificationId = confirmationResult.verificationId;

          // CODE SEND CORRECTLY, you can provide more actions here
        })
        .catch((error) => {
          this.spinnerEvent.emit(false);
          console.error('Sign in with phone number:',error.message);
          alert(error.message);
        });
  }
}
