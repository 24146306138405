<form
  class="registration-form no-back-button"
  [formGroup]="registrationForm"
  (ngSubmit)="continue()"
  #amountForm
>
  <div class="flex-box">
    <div class="upper">
      <h1 class="title">{{ "step1Title" | translate }}</h1>
      <p>{{ "step1.description" | translate }}</p>
      <mat-form-field class="full-width">
        <mat-label>{{ "initialAmount" | translate }}</mat-label>
        <input
          type="number"
          matInput
          formControlName="initialAmount"
          placeholder="Ex. 100"
        />
        <mat-error *ngIf="registrationForm.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="registrationForm.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{ "topupAmount" | translate }}</mat-label>
        <input
          type="number"
          matInput
          placeholder="Ex. 50"
          formControlName="topUpAmount"
        />
        <mat-error *ngIf="registrationForm.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="registrationForm.hasError('^(0|[1-9][0-9]*)$')">
          Must be positive value
        </mat-error>
      </mat-form-field>
    </div>
    <div class="bottom">
      <button
        [disabled]="registrationForm.invalid"
        mat-raised-button
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{ "continue" | translate }}
      </button>
    </div>
  </div>
</form>

<div class="flex-center">
  <img class="logo" src="assets/dw-logo.png" />
</div>
