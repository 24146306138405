import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IAmounts } from 'src/app/models/registration.model';

@Component({
  selector: 'app-step1-amounts',
  templateUrl: './step1-amounts.component.html',
  styleUrls: ['./step1-amounts.component.scss'],
})
export class Step1AmountsComponent {
  @Output() amountsEvent = new EventEmitter<IAmounts>();

  registrationForm = new FormGroup({
    initialAmount: new FormControl('', [
      Validators.required,
      Validators.min(50),
      Validators.pattern('^(0|[1-9][0-9]*)$'),
    ]),
    topUpAmount: new FormControl('', [
      Validators.required,
      Validators.pattern('^(0|[1-9][0-9]*)$'),
    ]),
  });
  continue() {
    if (this.registrationForm.valid)
      this.amountsEvent.emit({
        initialAmount: Number(this.registrationForm.value.initialAmount),
        topUpAmount: Number(this.registrationForm.value.topUpAmount),
      });
  }
}
