import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IExchangeData,
  IFRAME_MESSAGES,
} from 'src/app/models/registration.model';

@Component({
  selector: 'app-step4-tnc',
  templateUrl: './step4-tnc.component.html',
  styleUrls: ['./step4-tnc.component.scss'],
})
export class Step4TncComponent {
  @Output() tncContinueEvent = new EventEmitter<IExchangeData | null>();
  checked: any;
  retreivedDadaFromExchange: IExchangeData | null = null;

  @Output() spinnerEvent = new EventEmitter<boolean>();

  @HostListener('window:message', ['$event'])
  onMessage(ev: MessageEvent) {
    if (ev.data?.event == IFRAME_MESSAGES.SMART_DCA_CREDENTIALS_RESPONSE) {
      this.spinnerEvent.emit(false);
      this.retreivedDadaFromExchange = ev.data.data;
      this.tncContinueEvent.emit(ev.data.data);
    }
    return;
  }

  constructor(private route: ActivatedRoute) {}

  continue() {
    this.spinnerEvent.emit(true);
    setTimeout(() => {
      if (!this.retreivedDadaFromExchange) {
        this.tncContinueEvent.emit(null);
      }
    }, 6000);
    try {
      window.opener.postMessage(
        JSON.stringify({
          event: IFRAME_MESSAGES.SMART_DCA_CREDENTIALS_REQUEST,
        }),
        '*'
      );
    } catch (e) {
      try {
        window.parent.postMessage(
          { event: IFRAME_MESSAGES.SMART_DCA_CREDENTIALS_REQUEST },
          '*'
        );
      } catch (e) {
        throw Error(
          '[TNC] Cant use window.opener.postMessage nor window.parent.postMessage'
        );
      }
    }
  }
}
