import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  doc,
  setDoc,
} from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { exchangeConfig } from '../config/exchange.config';
import {
  IAmounts,
  IExchangeData,
  Registration,
} from '../models/registration.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  userDataSaved = false;

  firestore: Firestore = inject(Firestore); // inject Cloud Firestore
  usersCollection?: CollectionReference;
  success = true;

  registration!: Registration;
  currentStep = 1;
  languages = [
    { id: 'en', name: 'En' },
    { id: 'fr', name: 'Fr' },
    { id: 'it', name: 'It' },
  ];
  selectedLanguage = 'en';
  saveRetries = 0;
  exchangeDataSaved = false;
  description = '';

  constructor(
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private notifierService: NotifierService
  ) {
    console.log('version 1.0.14', environment.isDev);
  }

  /**
   * change language
   * @param selectedLanguage
   */
  languageChange(selectedLanguage: string) {
    this.translate.use(selectedLanguage);
  }

  /**
   *
   * @param $event
   */
  handleUserDada($event: IExchangeData | null) {
    if (!$event || !$event.public_key || !$event.secret_key) {
      this.description =
        'Missing params from exchange public_key or sercret_key';
      this.registrationErrored();
    } else {
      this.registration.apiKey = $event.public_key;
      this.registration.apiSecret = $event.secret_key;
      this.registration.userFirstName = $event.user;
      this.goToNextStep();
    }
  }

  /**
   * create crypto pair
   * @param symbol
   * @param percent
   * @returns
   */
  createSymbol(symbol: string, percent: number) {
    return {
      exchange: exchangeConfig.name,
      percentage: percent,
      strategy: 2,
      symbol: `${symbol}/${exchangeConfig.fiatCurrency}`,
      baseSymbol: symbol,
    };
  }

  /**
   * handle errors
   */
  registrationErrored() {
    this.success = false;
    this.spinner.hide();
    this.goToNextStep(environment.finalStepNumber);
    throw new Error(this.description);
  }

  /**
   * save all data to database
   * @param uid
   * @param phoneNumber
   * @returns
   */
  saveToDB(uid: string, phoneNumber: string) {
    if (!this.registration.cryptoPairs) {
      this.notifierService.notify('error', 'Please go back to first step');
      this.spinner.hide();
    }

    if (!this.registration.apiKey || !this.registration.apiSecret) {
      this.description = 'Missing secret_key or api_key';
      this.registrationErrored();
      return;
    }

    let activeSymbols = {};
    this.registration.cryptoPairs.push(exchangeConfig.cryptoCurrency);
    this.registration.cryptoPairs.forEach((pair) => {
      // @ts-ignore
      activeSymbols[`${pair}${exchangeConfig.fiatCurrency}`] =
        this.createSymbol(pair, 1);
    });

    const documentData = {
      activeSymbols: activeSymbols,
      createdAt: new Date(),
      email: `${this.registration.userFirstName}@youngplatform`,
      frequency: this.registration.frequency,
      investmentAmount: this.registration.amounts.initialAmount,
      investmentBalance: this.registration.amounts.initialAmount,
      isBotActive: true,
      key: uid,
      lastBotInteractionDate: 0,
      lastUserReport: 0,
      phoneNumber: phoneNumber,
      subscription: 'paid',
      subscriptionDue: new Date('12/12/2322'),
      status: 'idle',
      tradingCurrency: exchangeConfig.fiatCurrency,
      uid: uid,
      isDev: environment.isDev,
      topUpValue: this.registration.amounts.topUpAmount,
      fiatCurrencyPair:
        exchangeConfig.cryptoCurrency + '/' + exchangeConfig.fiatCurrency,
      user: this.registration.userFirstName,
    };

    this.saveUserData(uid, documentData);
    this.saveExchangeData(uid);

    // todo: need to be checked saving to db
    setTimeout(() => {
      if (!this.userDataSaved && this.saveRetries < 2) {
        this.saveRetries++;
        this.saveUserData(uid, documentData);
        this.saveExchangeData(uid);
      } else {
        // force hide
        this.spinner.hide();
        this.goToNextStep();
      }
    }, 6000);
  }

  /**
   * save exchange based data to DB
   * @param uid
   * @returns
   */
  saveExchangeData(uid: string) {
    if (this.exchangeDataSaved) {
      this.canGoToFinalStep();
      return;
    }

    const exchangeRef = doc(this.firestore, 'bot_exchanges', uid);

    let exchanegs_api_data = {
      apiKeys: {
        youngPlatform: {
          key: this.registration.apiKey,
          secret: this.registration.apiSecret,
        },
      },
    };

    setDoc(exchangeRef, exchanegs_api_data, { merge: true })
      .then(() => {
        this.exchangeDataSaved = true;
        this.canGoToFinalStep();
      })
      .catch((err) => {
        console.error('error while saving exchange to db');
        this.notifierService.notify(
          'Error',
          'Can`t save user! Please try again later'
        );
        console.error('Exchange saving error', err);
        this.description = 'Error saving exchange data to db ' + err.toString();
        this.registrationErrored();
      });
  }

  /**
   * check if all data was saved to user and exchange tables before going to last stepp
   */
  canGoToFinalStep() {
    if (this.exchangeDataSaved && this.userDataSaved) {
      this.goToNextStep();
    }
  }

  /**
   * save user data to db
   * @param uid
   * @param documentData
   * @returns
   */
  saveUserData(uid: string, documentData: any) {
    if (this.userDataSaved) {
      this.canGoToFinalStep();
      return;
    }

    const smartDCARef = doc(this.firestore, 'usersSmartDCA', uid);
    setDoc(smartDCARef, documentData)
      .then(() => {
        this.userDataSaved = true;
        this.success = true;
        this.spinner.hide();
        this.canGoToFinalStep();
      })
      .catch((err) => {
        this.description = 'Can`t save user data to db';
        this.notifierService.notify(
          'Error',
          'Can`t save user data! Please try again later'
        );
        console.error('User saving error', err);

        this.registrationErrored();
      });
  }

  otpVerified($event: { uid: string; phoneNumber: string }) {
    this.registration.userId = $event.uid;
    this.saveToDB($event.uid, $event.phoneNumber);
  }

  goToPreviousStep() {
    this.currentStep--;
    this.cd.markForCheck();
  }

  setPairs(pairsArray: string[]) {
    this.registration.cryptoPairs = pairsArray;
    this.goToNextStep();
  }

  ngOnInit(): void {
    this.registration = new Registration();
  }

  spinnerEvent(show: boolean) {
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  setAmounts($event: IAmounts) {
    this.registration.amounts = $event;
    if ($event.topUpAmount == 0) {
      this.currentStep++;
      this.registration.frequency = 30001;
    }
    this.goToNextStep();
  }

  setFrequency($event: number) {
    this.registration.frequency = $event;
    this.goToNextStep();
  }

  /**
   * go to the next step of registration
   */
  goToNextStep(stepNumber?: number) {
    // prevent going more than last step
    if (this.currentStep < environment.finalStepNumber) {
      stepNumber ? (this.currentStep = stepNumber) : this.currentStep++;
      this.cd.markForCheck();
    }
  }
}
