import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  userData: any;

  constructor(

    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    // var data = JSON.parse(localStorage.getItem('user_data') || '{}');
    // this.userData = data.user.phoneNumber;
  }

  logout() {

  }
}
