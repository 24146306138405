import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-step3-crypto-pairs',
  templateUrl: './step3-crypto-pairs.component.html',
  styleUrls: ['./step3-crypto-pairs.component.scss'],
})
export class Step3CryptoPairsComponent {
  @Output() setPairsEvent = new EventEmitter<string[]>();

  showError = false;
  cryptoPairs = [
    { name: 'ETH', description: 'Ethereum' },
    { name: 'BTC', description: 'Bitcoin' },
    { name: 'DOT', description: 'Polkadot' },
    { name: 'MATIC', description: 'Polygon' },
  ];
  checked = false;
  selectedOptions?: string[];

  continue() {
    this.showError = false;
    if (this.selectedOptions?.length == 2) {
      this.setPairsEvent.emit(this.selectedOptions);
    } else {
      this.showError = true;
    }
  }
}
