import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step2-frequency',
  templateUrl: './step2-frequency.component.html',
  styleUrls: ['./step2-frequency.component.scss'],
})
export class Step2FrequencyComponent implements OnInit {
  @Output() frequencyEvent = new EventEmitter<number>();

  step2Translation!: any;
  frequencyOptions: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.setTranslations();
    this.translate.onLangChange.subscribe(() => {
      this.setTranslations();
    });
  }

  setTranslations() {
    this.translate.get('step2').subscribe((translationObject: string) => {
      this.step2Translation = translationObject;

      this.frequencyOptions = [
        {
          title: this.step2Translation?.daily,
          description: this.step2Translation?.dailyDescription,
          value: 1,
          selected: true,
        },
        {
          title: this.step2Translation?.weekly,
          description: this.step2Translation?.weeklyDescription,
          value: 7,
          selected: false,
        },
        {
          title: this.step2Translation?.biWeekly,
          description: this.step2Translation?.biWeeklyDescription,
          value: 14,
          selected: false,
        },
        {
          title: this.step2Translation?.monthly,
          description: this.step2Translation?.monthlyDescription,
          value: 30,
          selected: false,
        },
      ];
    });
  }

  frequency: any;
  selectedFrequency: any;

  continue() {
    if (this.selectedFrequency)
      this.frequencyEvent.emit(this.selectedFrequency.value);
  }
}
