<div class="registration-form no-back-button">
  <div class="flex-box">
    <div class="upper">
      <div class="flex-container">
        <mat-icon *ngIf="success" class="flex-item ok" width="100px"
          >check_circle</mat-icon
        >
        <mat-icon *ngIf="!success" class="flex-item error" width="100px">{{
          "cancel" | translate
        }}</mat-icon>
      </div>

      <div class="flex-center">
        <h1 *ngIf="success">{{ "step6.success" | translate }}</h1>
        <h1 *ngIf="!success">{{ "step6.error" | translate }}</h1>
      </div>

      <div class="flex-center">
        <p *ngIf="success">{{ "step6.successDescription" | translate }}</p>
        <p *ngIf="!success">{{ "step6.errorDescription" | translate }}</p>
      </div>
    </div>
    <div class="flex-center bottom">
      <button
        (click)="close()"
        mat-raised-button
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{ "close" | translate }}
      </button>
    </div>

    <div class="flex-center">
      <img class="logo" src="assets/dw-logo.png" />
    </div>
  </div>
</div>
