<div class="registration-form">
  <div class="flex-box">
    <div class="upper">
      <h1 class="title">{{"step3.selectPair" | translate}}</h1>
      <p>{{"step3.select2pairs" | translate}}</p>
      <mat-selection-list #pairs [(ngModel)]="selectedOptions" class="mat-primary" color='primary'>
        <mat-list-option class="mat-primary no-wrap"
          title="pair32"
          *ngFor="let pair of cryptoPairs"
          class="list"
          [value]="pair.name"
        >
          <p class="top-p">
            <strong>{{ pair.description }}</strong>
          </p>
          <p class="bottom-p">{{ pair.name }}</p>
        </mat-list-option>
      </mat-selection-list>
      <span *ngIf="showError" class="error">*{{"step3.select2pairsError" | translate}}</span>


    </div>
    <div class="bottom">
      <button
        (click)="continue()"
        mat-raised-button
        color="primary"
        type="submit"
        class="full-width submit-button"
      >
        {{"continue" | translate}}
      </button>
    </div>
  </div>
</div>
<div class="flex-center">
  <img class="logo" src="assets/dw-logo.png" />
</div>
